<template>
  <div>
    <data-tables
      :data="tableData ? tableData : []"
      :pagination-props="paginationDef"
      :table-props="tableProps"
      :show-action-bar="false"
      :filters="[searchDef]"
      style="width: 100%"
      @row-click="handleRowClick"
      v-loading="loading"
    >
      <el-row class="table-header" slot="tool">
        <el-col class="table-header-name">
          <span
            ><template v-if="scopedToCompany && multiCompany"
              >{{ company.name }} </template
            >Support Requests</span
          >
        </el-col>
        <el-col class="table-header-container">
          <div style="flex: 1">
            <el-input
              v-model="searchDef.value"
              placeholder="Search By Subject..."
              size="small"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div style="margin-left: 1rem; flex: none">
            <el-button
              type="primary"
              size="small"
              @click="$modal.show('create-support-ticket')"
            >
              New
            </el-button>
          </div>
        </el-col>
      </el-row>

      <el-table-column label="Status" width="100" align="center">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.status === 'new'"
            size="mini"
            disable-transitions
            type="info"
          >
            New
          </el-tag>
          <el-tag
            v-if="scope.row.status === 'open'"
            size="mini"
            disable-transitions
            type="info"
          >
            Open
          </el-tag>
          <el-tag
            v-if="scope.row.status === 'pending'"
            size="mini"
            disable-transitions
            type="warning"
          >
            Pending
          </el-tag>
          <el-tag
            v-if="scope.row.status === 'hold'"
            size="mini"
            disable-transitions
          >
            On Hold
          </el-tag>
          <el-tag
            v-if="scope.row.status === 'solved'"
            size="mini"
            disable-transitions
            type="success"
          >
            Solved
          </el-tag>
          <el-tag
            v-if="scope.row.status === 'closed'"
            size="mini"
            disable-transitions
          >
            Closed
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        prop="company"
        label=""
        width="50"
        align="center"
        v-if="multiCompany && !scopedToCompany"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.company === 'self'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1400 1065.8"
              v-html="logo"
              style="width: 40px; height: auto"
            ></svg>
          </div>
          <div v-else-if="scope.row.company.primary_logo">
            <img
              :src="scope.row.company.primary_logo"
              style="width: 40px; height: auto"
              :alt="scope.row.company.name"
            />
          </div>
          <div
            style="
              width: 100%;
              white-space: nowrap;
              overflow-x: hidden;
              text-overflow: ellipsis;
              font-size: 0.8rem;
            "
            v-else
          >
            {{ scope.row.company.name }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="number"
        label="#"
        sortable="custom"
        width="100"
        align="center"
      >
        <template slot-scope="scope"> # {{ scope.row.id }} </template>
      </el-table-column>

      <el-table-column
        prop="subject"
        label="Subject"
        sortable="custom"
        min-width="150"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        prop="updated_at"
        label="Last Update"
        sortable="custom"
        width="180"
      >
        <template slot-scope="scope">
          <span
            v-text="
              dateTimeFormat(scope.row.updated_at, 'MM/DD/YYYY [at] hh:mm A')
            "
          ></span>
        </template>
      </el-table-column>
    </data-tables>
    <p
      style="margin-top: 1rem; text-align: center"
      v-if="scopedToCompany && multiCompany"
    >
      Currently displaying tickets for {{ company.name }}.
      <router-link :to="{ name: 'support' }">Click here</router-link> to view
      all tickets.
    </p>
  </div>
</template>

<style lang="scss">
.company-cell {
  padding: 0 !important;

  .cell {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
  }
}
</style>

<script>
import SingleTicketView from "@/partials/Support/SingleTicket"

import DateTime from "@/mixins/DateTime"

import { mapActions, mapGetters } from "vuex"

import svg from "@/theme/svg"

export default {
  name: "SupportTicketTable",

  mixins: [DateTime],

  data() {
    return {
      searchDef: {
        value: "",
        prop: "subject",
      },
      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      paginationDef: {
        pageSize: 20,
        pageSizes: [20],
        layout: "prev, pager, next",
      },
      tableProps: {
        border: false,
        stripe: true,
        defaultSort: {
          prop: "updated_at",
          order: "descending",
        },
        rowStyle: {
          cursor: "pointer",
        },
        cellClassName: this.getCellClassName,
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      data: "zendesk/tickets",
      auth: "auth/user",
      companies: "companies/list",
    }),
    tableData() {
      if (!this.data) return null
      if (this.scopedToCompany) {
        return this.$_.reject(this.data, (item) => {
          return item.company.slug !== this.$route.params.company
        })
      } else {
        return this.data
      }
    },
    logo() {
      return svg.comet
    },
    multiCompany() {
      if (!this.data) return false
      return (
        this.$_.uniq(
          this.$_.map(this.data, function (o) {
            if (o.company === "self") {
              return o.company
            } else {
              return o.company.slug ? o.company.slug : null
            }
          })
        ).length > 1
      )
    },
    company() {
      let index = this.$_.findIndex(this.companies, {
        slug: this.$route.params.company,
      })
      if (index === -1) return false
      return this.companies[index]
    },
    scopedToCompany() {
      return this.$route.name === "company_support"
    },
  },
  watch: {
    data(val) {
      if (val !== null) this.loading = false
    },
  },

  methods: {
    ...mapActions({
      refreshTickets: "zendesk/refreshTickets",
    }),
    handleRowClick(row) {
      this.$modal.show(
        SingleTicketView,
        {
          ticket: row,
        },
        {
          name: "single-support-ticket",
          adaptive: true,
          height: "auto",
          scrollable: true,
          width: 1000,
        }
      )
    },
    getCellClassName(props) {
      if (props.column.property === "company") {
        return "company-cell"
      } else {
        return ""
      }
    },
    routeChange() {
      if (this.scopedToCompany && !this.company) {
        this.$router.replace("/404")
      } else {
        document.title =
          this.$root.app.name +
          " - " +
          this.company.name +
          " - Support Requests"
      }
    },
  },

  created() {
    this.loading = true
    this.refreshTickets()
  },
}
</script>

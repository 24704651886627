<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span></span>
      <el-button type="text" @click="$modal.hide('single-support-ticket')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <div class="ticket-header">
      <el-row class="ticket-header-wrapper">
        <el-col class="ticket-header-name">
          <span>{{ ticket.subject }}</span
          ><br />
        </el-col>
        <el-col class="ticket-header-stats">
          <div class="ticket-header-priority" style="flex: none">
            <div
              v-if="ticket.priority === 'low'"
              class="text-default"
              v-text="'Low Priority'"
            ></div>
            <div
              v-if="ticket.priority === 'normal'"
              class="text-default"
              v-text="'Normal Priority'"
            ></div>
            <div
              v-if="ticket.priority === 'high'"
              class="text-warning"
              v-text="'High Priority'"
            ></div>
            <div
              v-if="ticket.priority === 'urgent'"
              class="text-danger"
              v-text="'Urgent'"
            ></div>
          </div>
          <div
            class="ticket-header-status"
            style="margin-left: 1rem; flex: none"
          >
            <div
              v-if="ticket.status === 'new'"
              class="text-info"
              v-text="'New'"
            ></div>
            <div
              v-if="ticket.status === 'open'"
              class="text-info"
              v-text="'Open'"
            ></div>
            <div
              v-if="ticket.status === 'pending'"
              class="text-warning"
              v-text="'Pending'"
            ></div>
            <div
              v-if="ticket.status === 'hold'"
              class="text-default"
              v-text="'On Hold'"
            ></div>
            <div
              v-if="ticket.status === 'solved'"
              class="text-success"
              v-text="'Solved'"
            ></div>
            <div
              v-if="ticket.status === 'closed'"
              class="text-default"
              v-text="'Closed'"
            ></div>
          </div>
          <div
            class="ticket-header-due"
            v-if="ticket.due_date"
            style="margin-left: 1rem; flex: none"
          >
            <span
              :class="{
                'text-danger':
                  ticket.due_date * 1000 < Date.now() &&
                  ['solved', 'closed'].indexOf(ticket.status) === -1,
              }"
              >Due {{ dateTimeFormat(ticket.due_date, "M/D/YY") }}</span
            >
          </div>
        </el-col>
      </el-row>
      <el-row class="ticket-header-date">
        <span>
          Created
          {{ dateTimeFormat(ticket.created_at, "M/D/YY [at] hh:mm A") }} by
          <strong>{{
            ticket.requester
              ? ticket.requester.slug === user.slug
                ? "you"
                : ticket.requester.name
              : ""
          }}</strong
          >.
        </span>
      </el-row>
    </div>
    <div class="loading-wrapper" v-loading="loading">
      <div class="ticket-comments">
        <el-row
          v-for="comment in comments"
          :key="comment.id"
          class="ticket-comment-wrapper"
        >
          <el-row class="ticket-comments-header">
            {{ dateTimeFormat(comment.created_at, "M/D/YY [at] hh:mm A") }} by
            <strong style="margin-left: 0.25rem">{{
              comment.author
                ? comment.author.slug === user.slug
                  ? "you"
                  : comment.author.name
                : ""
            }}</strong>
          </el-row>
          <el-row
            class="ticket-comments-content"
            v-html="comment.html_body"
          ></el-row>
        </el-row>
      </div>
      <hr style="margin: 1rem 0" />
      <div v-if="ticket.status !== 'closed'" class="ticket-response">
        <support-ticket-comment-form
          :ticket="ticket"
        ></support-ticket-comment-form>
      </div>
      <div v-else class="ticket-response">
        <div class="ticket-response-closed">
          This ticket has been closed. If you'd like to comment on this issue,
          please create a
          <a class="has-cursor-pointer" @click="followUpTicketModal"
            >follow up ticket</a
          >
          instead.
        </div>
      </div>
    </div>
  </el-card>
</template>

<style lang="scss">
</style>

<script>
import api from "@/api/zendesk"
import DateTime from "@/mixins/DateTime"
import SupportTicketCommentForm from "@/components/Forms/SupportTicketCommentForm"
import SupportTicketCreateForm from "@/components/Forms/SupportTicketCreateForm"
import { mapGetters } from "vuex"

export default {
  name: "SingleTicket",

  mixins: [DateTime],
  components: {
    SupportTicketCommentForm,
  },

  props: {
    ticket: {
      required: true,
    },
  },
  data() {
    return {
      comments: null,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    followUpTicketModal() {
      this.$modal.hide("single-support-ticket")
      this.$modal.show(
        SupportTicketCreateForm,
        {
          follow_up: this.ticket,
        },
        {
          name: "create-support-ticket-dynamic",
          adaptive: true,
          height: "auto",
          scrollable: true,
          width: 1000,
        }
      )
    },
  },

  created() {
    api.single(this.ticket.id, (ticket) => {
      this.comments = ticket
      this.loading = false
    })
  },
}
</script>

<template>
  <div class="new-comment-form">
    <el-row class="header"> Add a comment </el-row>
    <el-form class="comment-form" @submit.native.prevent="create">
      <el-form-item
        :error="form.errors.first('body') ? form.errors.first('body') : null"
        label=""
        style="margin-bottom: 10px"
      >
        <el-input
          type="textarea"
          :rows="3"
          v-model="comment.body"
          @focus="form.errors.clear('body')"
        >
        </el-input>
      </el-form-item>

      <el-row>
        <el-upload
          class="attachment-uploader"
          drag
          action="#"
          :http-request="uploadAttachment"
          :file-list="attachments"
          :before-upload="beforeUpload"
          :before-remove="beforeRemove"
          multiple
        >
          <div class="el-upload__text">
            Drop file here or <em>click to attach</em>
            <br />
            <small>Attachments must be less than 20 MB.</small>
          </div>
        </el-upload>
      </el-row>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Create
      </el-button>
    </el-form>
  </div>
</template>

<style lang="scss">
.attachment-uploader {
  margin-bottom: 1rem;
  .el-upload {
    width: 100%;
    max-height: 50px;
    .el-upload-dragger {
      width: 100%;
      max-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.new-comment-form {
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .header {
    display: flex;
    font-size: 0.9rem;
    padding: 0.25rem 0.5rem;
    background: #fafafa;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    color: #666;
    font-weight: 800;
  }

  .comment-form {
    padding: 0.5rem;
  }
}

.solved-alert {
  background: #fafafa;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 0.75rem;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
</style>

<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "SupportTicketCommentForm",

  props: {
    ticket: {
      required: true,
    },
  },
  data() {
    return {
      comment: {
        body: "",
        attached_tokens: {},
      },
      form: new Form(),
      loading: false,
      attachments: [],
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      updateTicket: "zendesk/updateTicket",
    }),
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20

      if (!isLt20M) {
        this.$message.warning("Attachments can not exceed 20MB!")
      }
      return isLt20M
    },
    beforeRemove(file) {
      delete this.ticket.attached_tokens[file.uid]
    },
    uploadAttachment(data) {
      this.loading = true

      let formData = new FormData()
      formData.append("file", data.file)

      this.axios
        .post("zendesk/tickets/attachment", formData)
        .then((response) => {
          this.loading = false
          this.comment.attached_tokens[data.file.uid] = response.data.data
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    create() {
      this.loading = true

      let data = this.$_.cloneDeep(this.comment)
      data.attached_tokens = Object.values(data.attached_tokens)

      this.axios
        .post("zendesk/tickets/comments/" + this.ticket.id + "/create/", data)
        .then((response) => {
          this.$modal.hide("single-support-ticket")
          this.updateTicket(response.data.data)
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },
}
</script>

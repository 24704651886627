<template>
  <el-card>
    <support-ticket-table></support-ticket-table>
  </el-card>
</template>

<style scoped>
</style>

<script>
import SupportTicketTable from "@/components/Tables/SupportTicket"

export default {
  name: "SupportPage",

  components: {
    SupportTicketTable,
  },
}
</script>
